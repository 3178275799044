import React from "react";
import { Box } from "@mui/material";
import "./BbbLogo.scss";

const BbbLogo = () => {
  return (
    <Box component="section" className="bbbLogo">
      <script
        type="text/javascript"
        src="https://seal-newyork.bbb.org/inc/legacy.js"
      ></script>
      <a
        href="https://www.bbb.org/us/ny/new-york/profile/student-loan-services/yelo-funding-inc-0121-87176599/#sealclick"
        id="bbblink"
        className="ruhzbul"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <img
          src="https://seal-newyork.bbb.org/logo/ruhzbul/bbb-87176599.png"
          alt="YELO Funding, Inc. BBB Business Review"
        />
      </a>
    </Box>
  );
};

export default BbbLogo;
